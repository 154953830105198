<template>
  <div id="faq-modal-component" class="card">
    <div class="faq-modal-header">
      <h2 class="faq-modal-header-text">
        {{ categoryName }}
      </h2>
    </div>
    <hr>
    <associated-pages
      :category-id="categoryId"
      :max-pages="3"
    />
    <div class="faq-modal-footer">
      <hr>
      <b-button
        class="category-card-button btn outline-primary rounded"
        :aria-label="buttonLabel"
        @click="navigateToCategory"
      >
        View Category
      </b-button>
    </div>
  </div>
</template>

<script>
import AssociatedPages from '@/components/Faq/AssociatedPages.vue'
import { faqMixin } from '@/mixins/faqMixin'

export default {
  name: 'FaqModal',
  components: {
    AssociatedPages,
  },
  mixins: [faqMixin],
  props: {
    categoryName:{
      type: String,
    },
    categoryId:{
      type: String,
      required: true,
    },
  },
  data() {
    return {
      buttonLabel: `Navigate to ${this.categoryName} button`,
    }
  },
  methods: {
    navigateToCategory() {
      this.logFaqCategoryNavigation(this.categoryId, {
        action: 'visit',
        type: 'button',
        categoryName: this.categoryName,
      })
    },
  },
}
</script>

<style lang="scss">
#faq-modal-component {
  width: 34.0625rem;
  min-height: 12.5rem;
  margin-bottom: 2.0rem;
  vertical-align: top;

  .faq-modal-header {
    padding: 1.5rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    .faq-modal-header-text {
      width: 100%;
      margin: 0;
      font-size: 1.125rem;
      font-weight: 600;
      color: #4E4E52;
    }
  }

  hr {
    margin-top: 0;
    margin-bottom: 0;
  }

  .faq-modal-footer {
    margin-top: auto;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;

    hr {
      width: 100%;
      margin-top: 0.5rem;
    }

    .category-card-button {
      margin: 1.0rem 0;
      padding: 0.5rem 2.0rem;
      font-size: 1.0rem;
      font-weight: 600;
      color: #2E3798;
      background: white;
      border-color: #2E3798;
      border-radius: 0.3125rem;

      &:hover {
        color: white;
        background: #2E3798;
        border-color: #2E3798;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  #faq-modal-component {
    .faq-modal-header {
      padding: 1.0rem;

      .faq-modal-header-text {
        text-align: left;
      }
    }

    .faq-modal-footer .category-card-button {
        width: 90%;
    }
  }
}
</style>
