<template>
  <div class="faq-search">
    <div class="input-search-container">
      <search-icon class="search-icon" />
      <input
        class="faq-search-input"
        type="text"
        placeholder="Search Topics"
        @input="debounceSearch"
        @focus="showResults"
        @blur="hideResults"
      >
    </div>
    <!--Results only populate/show if the user is clicked into the search bar and actively using it.-->
    <div v-if="resultsVisible && (typing || message)" class="faq-search-results" @mousedown.prevent>
      <ct-centered-spinner v-if="!resultsLoaded" />
      <ul v-if="searchResults.length > 0">
        <li v-for="(result, index) in searchResults" :key="result.id">
          <template v-if="result.type === 'category'">
            <a :href="`#/faq/${result.id}`">
              <h6> See Category: {{ result.name }}</h6>
            </a>
            <hr v-if="index !== searchResults.length - 1">
          </template>
          <template v-else>
            <a :href="`#/faq/${result.faq_category_id}/${result.id}`">
              <h6>{{ result.question }}</h6>
            </a>
            <a :href="`#/faq/${result.faq_category_id}`">
              <p>Category:
                <span class="rounded-category">{{ getCategoryName(result.faq_category_id) }}</span>
              </p>
            </a>
            <hr v-if="index !== searchResults.length - 1">
          </template>
        </li>
      </ul>
      <ul v-else-if="message">
        <li>
          <h6>No results found</h6>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Fuse from 'fuse.js'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
export default {
  name: 'FaqSearchBar',
  components: {
    CtCenteredSpinner,
    SearchIcon: () => import('@images/ui/search-icon.svg'),
  },
  data() {
    return {
      message: null,
      typing: null,
      debounce: null,
      fuse: null,
      resultsLoaded: null,
      resultsVisible: false,
      searchResults: [],
    }
  },
  computed: {
    ...mapGetters('faq', ['faqInformation']),
  },
  watch: {
    message() {
      this.search()
    },
  },
  async mounted() {
    await this.initializeFuse()
  },
  methods: {
    debounceSearch(event) {
      this.resultsLoaded = false
      this.message = null
      this.typing = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.typing = false
        this.message = event.target.value
      }, 600)
    },
    search() {
      this.resultsLoaded = false
      this.searchResults = []
      if (this.message && this.message.length >= 3) {
        const query = this.message.toLowerCase()
        const results = this.fuse.search(query, { limit: 5 })
        this.searchResults = results.map(result => result.item)
      }
      this.resultsLoaded = true
    },
    getCategoryName(categoryId) {
      const category = this.faqInformation?.find(cat => cat.id === categoryId)
      return category ? category.name : null
    },
    initializeFuse() {
      const options = {
        keys: ['question', 'name', 'content'],
        threshold: 0.4,
        ignoreLocation: true,
      }
      const categoryResults = this.faqInformation
        .filter(category => category.is_visible)
        .map(category => {
        return {
          id: category.id,
          name: category.name,
          question: '',
          type: 'category',
          content: '',
        }
      })
      let results = [...categoryResults]
      this.faqInformation.forEach(category => {
        if (category.is_visible) {
          category.faq_pages.forEach(page => {
            if (page.is_visible) {
              results.push(page)
            }
          })
        }
      })
      this.fuse = new Fuse(results, options)
    },
    showResults() {
      this.resultsVisible = true
    },
    hideResults() {
      setTimeout(() => {
        this.resultsVisible = false
      }, 100)
    },
  },
}
</script>

<style lang="scss">
.faq-search {
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;
}
.input-search-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #c2c3cc;
  width: 472px;
  height: 46px;
}
.search-icon {
  margin-left: 10px;

}
.faq-search-input {
  border: none;
  padding-left: 5px;
  height: 100%;
  font-size: 16px;
}
.faq-search-results {
  text-align:left;
  position:absolute;
  background-color:white;
  border:1px #C2C3CC solid;
  z-index:1;
  border-radius:5px;
  left:50%;
  top: 100%;
  transform: translate(-50%, 0%);
  width: 100%;
  max-width:472px;
  margin-top:2px;
  h6 {
    margin-top:10px;
    margin-left:18px;
    margin-bottom: 3px;
    color: #3299C7;
  }
  hr {
    margin-bottom: 0;
    margin-top: 10px;
    width: 92%;
  }
  p {
    font-size: 12px;
    margin-left: 18px;
    margin-bottom: 0;
    color: #404040;
    font-weight: 500;
  }
  ul {
    list-style: none;
    padding-inline-start: 0;
  }
  h6:hover {
    background-color:whitesmoke;
  }
  .rounded-category {
    font-size: 10px;
    font-weight: 700;
    background-color: #E2E2E5;
    border-radius: 6px;
    padding: 2px 8px;
    margin-left: 8px;
  }
}
</style>
