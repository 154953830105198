var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "faq-search" }, [
    _c(
      "div",
      { staticClass: "input-search-container" },
      [
        _c("search-icon", { staticClass: "search-icon" }),
        _c("input", {
          staticClass: "faq-search-input",
          attrs: { type: "text", placeholder: "Search Topics" },
          on: {
            input: _vm.debounceSearch,
            focus: _vm.showResults,
            blur: _vm.hideResults,
          },
        }),
      ],
      1
    ),
    _vm.resultsVisible && (_vm.typing || _vm.message)
      ? _c(
          "div",
          {
            staticClass: "faq-search-results",
            on: {
              mousedown: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            !_vm.resultsLoaded ? _c("ct-centered-spinner") : _vm._e(),
            _vm.searchResults.length > 0
              ? _c(
                  "ul",
                  _vm._l(_vm.searchResults, function (result, index) {
                    return _c(
                      "li",
                      { key: result.id },
                      [
                        result.type === "category"
                          ? [
                              _c(
                                "a",
                                { attrs: { href: `#/faq/${result.id}` } },
                                [
                                  _c("h6", [
                                    _vm._v(
                                      " See Category: " + _vm._s(result.name)
                                    ),
                                  ]),
                                ]
                              ),
                              index !== _vm.searchResults.length - 1
                                ? _c("hr")
                                : _vm._e(),
                            ]
                          : [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: `#/faq/${result.faq_category_id}/${result.id}`,
                                  },
                                },
                                [_c("h6", [_vm._v(_vm._s(result.question))])]
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: `#/faq/${result.faq_category_id}`,
                                  },
                                },
                                [
                                  _c("p", [
                                    _vm._v("Category:\n              "),
                                    _c(
                                      "span",
                                      { staticClass: "rounded-category" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getCategoryName(
                                              result.faq_category_id
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              index !== _vm.searchResults.length - 1
                                ? _c("hr")
                                : _vm._e(),
                            ],
                      ],
                      2
                    )
                  }),
                  0
                )
              : _vm.message
              ? _c("ul", [_vm._m(0)])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("h6", [_vm._v("No results found")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }