<template>
  <b-container id="faq-landing-page">
    <div class="faq-landing-header">
      <h1>Frequently Asked Questions</h1>
    </div>
    <div class="faq-landing-search-header">
      <h2>What can we help you with?</h2>
      <faq-search-bar v-if="loaded" />
    </div>
    <ct-centered-spinner v-if="loading" />
    <div v-else class="faq-modals-holder">
      <faq-modal
        v-for="category in visibleCategories"
        :key="category.id"
        :category-name="category.name"
        :category-id="category.id"
      />
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FaqModal from '@/components/Faq/FaqModal.vue'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import FaqSearchBar from '@/components/Faq/FaqSearchBar.vue'

export default {
  name: 'Faq',
  components: {
    CtCenteredSpinner,
    FaqModal,
    FaqSearchBar,
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters('faq', ['faqInformation']),
    visibleCategories() {
      return this.faqInformation.filter(category => category.is_visible)
    },
    loaded() {
      return this.faqInformation && this.faqInformation.length > 0
    },
  },
  async mounted() {
    try {
      if (!this.loaded) await this.fetchFaqInformation()
    } finally {
      this.loading = false
    }
  },
  methods: {
    ...mapActions('faq', ['fetchFaqInformation']),
  },
}
</script>

<style lang="scss" scoped>
h1,
h2 {
  margin: 0 0 1.5rem;
  text-align: center;
}

#faq-landing-page {

  .faq-landing-header {
    margin: 3.0rem 0;

    h1 {
      font-size: 1.75rem;
      font-weight: 700;
      color: #4E4E52;
    }
  }

  .faq-landing-search-header {
    margin-bottom: 2.0rem;

    h2 {
      margin-bottom: 1.0rem;
      color: #4E4E52;
      font-size: 1.125rem;
      font-weight: 400;
    }
  }

  .faq-modals-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 576px) {
  h1,
  h2 {
    text-align: left;
  }

  #faq-landing-page {

    .faq-landing-header {
      margin: 1.0rem 0 2.0rem;
    }

    .faq-landing-search-header h2 {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
